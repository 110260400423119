import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { FaCheck } from "react-icons/fa"

import "./style.scss"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        cta: file(relativePath: { regex: "/components/cta.yaml/" }) {
          yaml: childComponentsYaml {
            advantages
            button
          }
        }
      }
    `}
    render={data => (
      <aside className="c-call-to-action">
        <div className="c-call-to-action__inner l-container">
          <div className="c-call-to-action__content">
            <div className="c-advantages">
              {data.cta.yaml.advantages.map((item, key) => (
                <div className="c-advantage" key={key}>
                  <FaCheck className="c-advantage__icon" />

                  <div className="c-advantage__text">{item}</div>
                </div>
              ))}
            </div>
            <Link
              to="https://mijn.homeworks.nl/intake/companyworks/client"
              className="c-call-to-action__btn c-btn c-btn--large"
            >
              {data.cta.yaml.button}
            </Link>
          </div>
        </div>
      </aside>
    )}
  />
)
