import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "~components/layout/full"
import Seo from "~components/seo"
import CallToAction from "~components/call-to-action"
import Help from "~components/help";

export const query = graphql`
  query {
    markdownRemark(fileAbsolutePath: { regex: "/pages/about_us.md/" }) {
      frontmatter {
        headline
        intro
        seo {
          title
          description
          keywords
        }
        toppers {
          headline
          description
          people {
            name
            subtitle
            picture {
              childImageSharp {
                fluid(
                  quality: 90
                  maxWidth: 468
                  maxHeight: 670
                  cropFocus: ATTENTION
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        team {
          headline
          description
          people {
            name
            role
            thumbnail {
              childImageSharp {
                fluid(
                  quality: 90
                  maxWidth: 468
                  maxHeight: 468
                  cropFocus: ATTENTION
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        collaboration {
          headline
          companies {
            name
            thumbnail {
              childImageSharp {
                fluid(quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          footnote
        }
      }
      html
    }
  }
`

export default ({ data, location }) => {
  return (
    <Layout>
      <Seo
        title={data.markdownRemark.frontmatter.seo.title}
        description={data.markdownRemark.frontmatter.seo.description}
        keywords={data.markdownRemark.frontmatter.seo.keywords}
        canonical={location.pathname}
      />

      <section className="c-page l-container">
        <header className="c-page__main">
          <div className="c-page__sidebar"></div>
          <div className="c-page__content">
            <h1 className="l-headline--primary">
              {data.markdownRemark.frontmatter.headline}
            </h1>
            <div className="c-page__intro">
              {data.markdownRemark.frontmatter.intro}
            </div>
          </div>
          <div className="c-page__sidebar"></div>
        </header>

        <div className="c-page__main">
          <div className="c-page__sidebar"></div>
          <div
            className="c-page__content c-page__markdown"
            dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
          ></div>
          <div className="c-page__sidebar"></div>
        </div>
      </section>

      <section className="c-team">
        <div className="c-team__inner l-container">
          <h2 className="c-team__headline l-headline--secondary">
            {data.markdownRemark.frontmatter.toppers.headline}
          </h2>

          <div className="c-members">
            {data.markdownRemark.frontmatter.toppers.people.map(
              (topper, key) => (
                <div className="c-member" key={key}>
                  {topper.picture && topper.picture.childImageSharp ? (
                    <Img
                      fluid={topper.picture.childImageSharp.fluid}
                      alt={topper.name}
                      className="c-member__img"
                    />
                  ) : (
                    <div className="c-member__img"></div>
                  )}
                  <div className="c-member__content">
                    <h3 className="c-member__name l-headline--tertiary">
                      {topper.name}
                    </h3>
                    <div className="c-member__role">{topper.subtitle}</div>
                  </div>
                </div>
              )
            )}
          </div>

          <div className="c-team__info">
            {data.markdownRemark.frontmatter.toppers.description}
          </div>

          {/*        <div className="c-team__btn">
            <a href="#" className="c-btn-secondary">Vacatures</a>
          </div>*/}
        </div>
      </section>

      <section className="c-page l-container">
        <div className="c-page__header">
          <div className="c-page__header-box">
            <h2
              className="l-headline--secondary"
              style={{ textAlign: "center" }}
            >
              {data.markdownRemark.frontmatter.team.headline}
            </h2>
            <div className="c-page__description">
              {data.markdownRemark.frontmatter.team.description}
            </div>
          </div>
        </div>

        <div className="c-members c-members--grid">
          {data.markdownRemark.frontmatter.team.people.map((member, key) => (
            <div className="c-member" key={key}>
              {member.thumbnail && member.thumbnail.childImageSharp ? (
                <Img
                  fluid={member.thumbnail.childImageSharp.fluid}
                  alt={member.name}
                  className="c-member__img"
                />
              ) : (
                <div className="c-member__img"></div>
              )}
              <div className="c-member__content">
                <h3 className="c-member__name l-headline--tertiary">
                  {member.name}
                </h3>
                <div className="c-member__role">{member.role}</div>
              </div>
            </div>
          ))}
        </div>

        {/*        <div className="c-team__btn">
          <a href="#" className="c-btn-secondary">Toon alles</a>
        </div>*/}
      </section>

      <section className="c-page l-container">
        <h2 className="l-headline--secondary" style={{ textAlign: "center" }}>
          {data.markdownRemark.frontmatter.collaboration.headline}
        </h2>

        <div className="c-companies">
          {data.markdownRemark.frontmatter.collaboration.companies.map(
            (company, key) => (
              <div className="c-company" key={key}>
                {company.thumbnail && company.thumbnail.childImageSharp ? (
                  <Img
                    fluid={company.thumbnail.childImageSharp.fluid}
                    alt={company.name}
                    imgStyle={{ objectFit: "contain" }}
                    className="c-company__img"
                  />
                ) : (
                  ""
                )}
              </div>
            )
          )}
        </div>

        <div className="c-page__main">
          <div className="c-page__sidebar"></div>
          <div className="c-page__content">
            <div className="c-page__description">
              {data.markdownRemark.frontmatter.collaboration.footnote}
            </div>
          </div>
          <div className="c-page__sidebar"></div>
        </div>
      </section>

      <CallToAction />
      <Help />
    </Layout>
  )
}
